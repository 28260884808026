import { Route, Routes } from 'react-router-dom';
import ErrorPage from './components/Pages/ErrorPage';
import PortfolioDetailsPage from './components/Pages/PortfolioDetailsPage';
import CaseStudyDetailsPage from './components/Pages/CaseStudyDetailsPage';
import GCHome from './components/Home/GCHomePage';
import Login from './dashpages/Login';
import Register from './dashpages/Register';
import Dashboard from './dashpages/Dashboard';
import CoursePage from './dashpages/CoursePage';
import ViewCoursePage from './dashpages/ViewCoursePage';
import ForgetPassword from './dashpages/ForgetPassword';
import VerifyEmail from './dashpages/VerifyEmail';
import Subscription from './dashpages/Subscription';
import Payment from './dashpages/Payment';
import BankTransfer from './dashpages/BankTransfer';
import Referlink from './dashpages/Referlink';
// eslint-disable-next-line
import Home from './components/Pages/Home';



function App() {
  return (
    <>
      <Routes>
        <Route path="/" >
          {/* <Route index element={<Layout />} /> */}
          <Route
            path="/"
            element={<GCHome  />}
          />
          
          <Route path="e-book" element={<PortfolioDetailsPage />} />
          <Route path="login" element={<Login />} />
          <Route path="register" element={<Register />} />
          <Route path="dashboard" element={<Dashboard />} />
          <Route path="course/:courseId" element={<CoursePage />}/>
          <Route path="View" element={<ViewCoursePage />}/>
          <Route path="ForgetPassword" element={<ForgetPassword />}/>
          <Route path="VerifyEmail" element={<VerifyEmail />}/>
          <Route path="Subscription" element={<Subscription />}/>
          <Route path="Payment" element={<Payment />}/>
          <Route path="BankTransfer" element={<BankTransfer />}/>
          <Route path="Referlink" element={<Referlink />}/>
          <Route path="privacyPolicy" element={<CaseStudyDetailsPage />}/>
        </Route>
        {/* <Route path="/dashboard" >

        </Route> */}

        <Route path="*" element={<ErrorPage />} />
      </Routes>
    </>
  );
}

export default App;
