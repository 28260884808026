import React, { useState,useEffect } from 'react';
import { useParams } from 'react-router-dom'
import { pageTitle } from '../../helper'
import Cta from '../Cta'
import PageHeading from '../PageHeading'
import Div from '../Div'
import SectionHeading from '../SectionHeading'
import Spacing from '../Spacing'
import Header from '../Header';
import { getTranslation } from '../Home/languageManager';

export default function PortfolioDetailsPage() {
  const params = useParams()
  pageTitle('GCrypto E-Book');
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  const [selectedLanguage, setSelectedLanguage] = useState('TKM');
  const translations = getTranslation(selectedLanguage);

  // Function to handle language change
  const handleLanguageChange = (language) => {
    setSelectedLanguage(language);
  };

  return (
    <>
      <Header selectedLanguage={selectedLanguage} onLanguageChange={handleLanguageChange} translations={translations} />
      <PageHeading 
        title='CRYPTO PUL DÜNÝÄSI'
        bgSrc='/images/service_hero_bg.jpeg'
        pageLinkText={params.portfolioDetailsId}
      />
      <Spacing lg='150' md='80'/>
      <Div className="container">
        {/* <box ><img src="/images/10.jpg" alt="Details" className="cs-radius_15 w-100" style={{maxHeight : '10vh'}}/></box> */}
        
        <Spacing lg='90' md='40'/>
        <Div className="row">
          <Div className="col-lg-6">
            <SectionHeading 
              title='CRYPTO PUL DÜNÝÄSI' 
              subtitle='E-Kitab' 
            >
              <Spacing lg='40' md='20'/>
              <p>Dostlar biz öz toparymyz bilen siziň gelejegiňizi üýtgedip biljek CRYPTO PUL DÜNÝÄSI atly kitabymyzy size hödürleyas ! Bu kitapda Crypto barada gyzyklanýan soraglarynyzyň ählisiniň jogabyny tapyp bilersiňiz ! </p>
              <Spacing lg='10' md='10'/>
              <p>Kitabymyz ýönekey dilde  düşündürüş bilen ýazylan.İndi siziň üçin hasam aňsat siz hem durmuşyňyzy üytgetmek isleseňiz şu web sahypamyzdan  satyn alyp bilersiniz !</p>
              <p>Bu Kitabymyzy kartyňyz bolmadan hem şu linkimize geçip satyn alyp bilersiňiz !!!</p>
            </SectionHeading>
          </Div>
          <Div className="col-lg-5 offset-lg-1">
            <Spacing lg='60' md='40'/>
            <h2 className='cs-font_30 cs-font_26_sm cs-m0'>Info -</h2>
            <Spacing lg='50' md='30'/>
            <Div className="row">
              <Div className="col-6">
                <h3 className='cs-accent_color cs-font_22 cs-font_18_sm cs-m0'>Category:</h3>
                <p className='cs-m0'>Crypto/Blockchain</p>
                <Spacing lg='30' md='30'/>
              </Div>
              <Div className="col-6">
                <h3 className='cs-accent_color cs-font_22 cs-font_18_sm cs-m0'>Location:</h3>
                <p className='cs-m0'>Poland</p>
                <Spacing lg='30' md='30'/>
              </Div>
              <Div className="col-6">
                <h3 className='cs-accent_color cs-font_22 cs-font_18_sm cs-m0'>Author:</h3>
                <p className='cs-m0'>Myrad Greatt</p>
                <Spacing lg='30' md='30'/>
              </Div>
              <Div className="col-6">
                <h3 className='cs-accent_color cs-font_22 cs-font_18_sm cs-m0'>Published:</h3>
                <p className='cs-m0'>23-Dec-2023</p>
                <Spacing lg='30' md='30'/>
              </Div>
              <Div className="col-6">
                <h3 className='cs-accent_color cs-font_22 cs-font_18_sm cs-m0'>Publisher:</h3>
                <p className='cs-m0'>GreattUniversity.com</p>
                <Spacing lg='30' md='30'/>
              </Div>
            </Div>
          </Div>
        </Div>
        <Spacing lg='65' md='10'/>
          <Div className="cs-page_navigation cs-center">
            {/* <Div>
              <Button btnLink='/portfolio/portfolio-details' btnText='Prev Project' variant='cs-type1'/>
            </Div> */}
            <Div>
              {/* <Button className="cs-btn cs-style1" btnLink='/portfolio/portfolio-details' btnText='Next Project' /> */}
              <Div className="col-sm-12">
                <button className="cs-btn cs-style1" >  
                  <a href="https://buy.stripe.com/aEUdRx6z7c0nfssaEE"><span>Pay Now</span></a>
                  {/* <Icon icon="bi:arrow-right" /> */}
                </button>
                <button className="cs-btn cs-style1" style={{marginLeft: '15px'}}>  
                <a href="https://www.instagram.com/greattuniversity?igsh=MzRlODBiNWFlZA=="><span>Instagram Link</span></a>
                </button>
              </Div>
            </Div>
          </Div>
      </Div>
      <Spacing lg='145' md='80'/>
      <Cta 
        title='greattuniversity@gmail.com' 
        bgSrc='/images/cta_bg_2.jpeg'
        variant='rounded-0'
      />
    </>
  )
}
