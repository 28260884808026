import * as React from 'react';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import Link from '@mui/material/Link';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { useState } from 'react';
import {SendCode} from '../APIs/SendCode'; 
import {ResetPasswordUser} from '../APIs/ResetPassword'; 

function Copyright(props) {
  return (
    <Typography variant="body2" color="text.secondary" align="center" {...props}>
      {'Copyright © '}
      <Link color="inherit" href="https://mui.com/">
        Your Website
      </Link>{' '}
      {new Date().getFullYear()}
      {'.'}
    </Typography>
  );
}

// TODO remove, this demo shouldn't need to reset the theme.

const darkTheme = createTheme({
    palette: {
      mode: 'dark',
    },
    typography: {
      fontFamily: 'Raleway, Arial, sans-serif',
    },
  });

export default function SignUp() {

  const [email, setEmail] = useState('');
  const [code, setCode] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [message, setMessage] = useState('');
  const [displayEmail, setdisplayEmail] = useState('block');
  const [displayCode, setdisplayCode] = useState("none");
  const [displayNewPassword, setdisplayNewPassword] = useState('none');
  const [btnText, setbtnText] = useState('Send Code');

  

  const handleSubmit = async (event) => {
    event.preventDefault(); // Prevent default form submission behavior
    try {

      if(displayEmail === 'block'){
        const sendCodeData = await SendCode(email);
        setMessage(sendCodeData);
        setdisplayEmail('none');
        setdisplayCode('block');
        setdisplayNewPassword('block');
        setbtnText('Submit');
  
      }

      if(code !== "" && newPassword !== ""){
        const resetPasswordUser = await ResetPasswordUser(code,newPassword);
        sessionStorage.setItem('userId', resetPasswordUser.userId);
        sessionStorage.setItem('accessToken', resetPasswordUser.accessToken);
        window.location.href ='/dashboard';
      }

      // /window.location.href =loginData.path;
    } catch (error) {
      setMessage(error.message);
    }
  };

  return (
    <ThemeProvider theme={darkTheme}>
      <Container component="main" maxWidth="xs"  sx={{
            height : '100vh'
          }}>
        <CssBaseline />
        <Box
          sx={{
            marginTop: 8,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            width: '100%', // Take up 100% width
            height: '100vh',
          }}
        >
          <img alt ="" src='../dash-images/logo.svg' href="/" style={{ height: '40px', marginBottom: '25px'}} /> 
          <Typography component="text" variant="h5">
            Submit new password
          </Typography>
          <Box component="form" noValidate onSubmit={handleSubmit} sx={{ mt: 3 }}>
            <Grid container spacing={2}>
              <Grid item xs={12} sx={{display : `${displayEmail}`}}>
                <TextField
                  required
                  fullWidth
                  id="email"
                  label="Email Address"
                  name="email"
                  autoComplete="email"
                  value={email} 
                  onChange={(e) => setEmail(e.target.value)}
                />
              </Grid>
              <Grid item xs={12} sx={{display : `${displayCode}`}}>
                <TextField
                  required
                  fullWidth
                  name="code"
                  label="Code"
                  type="text"
                  id="code"
                  autoComplete="code"
                  value={code} 
                  onChange={(e) => setCode(e.target.value)}
                />
              </Grid>
              <Grid item xs={12} sx={{display : `${displayNewPassword}`}}>
                <TextField
                  required
                  fullWidth
                  name="New Password"
                  label="New Password"
                  type="text"
                  id="NewPassword"
                  autoComplete="password"
                  value={newPassword} 
                  onChange={(e) => setNewPassword(e.target.value)}
                />
              </Grid>
              <Grid item xs={12}>
                <Typography component="text" variant="h5" sx ={{ fontSize : '12px', justifyContent : 'Center'}}>
                {message}
                </Typography>
              </Grid>
            </Grid>
            <Button
              type="submit"
              fullWidth
              variant="contained"
              sx={{ mt: 3, mb: 2,
                color: 'white', // Text color
                backgroundColor: '#0060ff', // Background color
                '&:hover': {
                    backgroundColor: '#0085ff', // Hover background color
                }
            }}
            >
              {btnText}
            </Button>
            <Grid container justifyContent="flex-end">
              <Grid item>
                <Link href="/login" variant="body2" sx={{ color: '#0085ff'}}>
                  Already have an account? Sign in
                </Link>
              </Grid>
            </Grid>
          </Box>
        </Box>
        <Copyright sx={{ mt: 5 }} />
      </Container>
    </ThemeProvider>
  );
}