import React, { useEffect, useState } from 'react';
import { Link, NavLink} from 'react-router-dom';
import './header.scss';
import Div from '../Div';
import DropDown from './DropDown';


export default function Header({ variant, selectedLanguage, onLanguageChange, translations}) {
  const [isSticky, setIsSticky] = useState(false);
  const [mobileToggle, setMobileToggle] = useState(false);
  
    // Handle login logic
  const handleLogin = () => {
    const token = sessionStorage.getItem('accessToken');
    const userId = sessionStorage.getItem('userId');
    if (token && userId) {
        window.location.href = '/dashboard';
    } else {
        window.location.href = '/login';
    }
  };

  // Function to handle language change
  const handleLanguageChange = (language) => {
    onLanguageChange(language);
    setMobileToggle(false);
  };

  useEffect(() => {
    window.addEventListener('scroll', () => {
      if (window.scrollY > 0) {
        setIsSticky(true);
      } else {
        setIsSticky(false);
      }
    });
  }, []);

  const scrollToSection = (sectionId) => {
    const section = document.getElementById(sectionId);
    if (section) {
      window.scrollTo({
        top: section.offsetTop - 50, // Adjust the offset as needed
        behavior: 'smooth',
      });
    }
    setMobileToggle(false); // Close the mobile menu after clicking
  };

  return (
    <>
      <header
        className={`cs-site_header cs-style1 text-uppercase ${
          variant ? variant : ''
        } cs-sticky_header ${isSticky ? 'cs-sticky_header_active' : ''}`}
      >
        <Div className="cs-main_header">
          <Div className="container">
            <Div className="cs-main_header_in">
              <Div className="cs-main_header_left">
                <Link className="cs-site_branding" to="/" onClick={() => scrollToSection('home')}>
                  <img src="/images/logo.svg" alt="Logo" />
                </Link>
              </Div>
              <Div className="cs-main_header_center">
                <Div className="cs-nav cs-primary_font cs-medium">
                  <ul 
                    className="cs-nav_list"
                    style={{ display: `${mobileToggle ? 'block' : 'none'}` }}
                  >
                    <li className="menu-item-has-children">
                      <NavLink to="/" onClick={() => scrollToSection('home')}>
                      {translations.Header.Home}
                      </NavLink>
                      <DropDown>
                        <ul>
                          <li>
                            <Link to="/login" onClick={() => scrollToSection('about')}>
                              About
                            </Link>
                          </li>
                          <li>
                            <Link to="/" onClick={() => scrollToSection('team')}
                            >
                              Team
                            </Link>
                          </li>
                          <li>
                          <Link to="/" onClick={() => scrollToSection('faq')}
                            >
                              FAQ
                            </Link>
                          </li>
                          <li>
                          <Link to="/" onClick={() => scrollToSection('contact')}
                            >
                              Contact
                            </Link>
                          </li>
                        </ul>
                      </DropDown>
                    </li>
                    <li>
                      <NavLink
                        to="/#service"
                        onClick={() => scrollToSection('service')}
                      >
                        {translations.Header.Service}
                      </NavLink>
                    </li>
                    <li>
                      <NavLink
                        to="/#strategie"
                        onClick={() => scrollToSection('strategie')}
                      >
                        {translations.Header.Strategie}
                      </NavLink>

                    </li>
                    <li>
                      <NavLink
                        to="/e-book"
                        onClick={() => setMobileToggle(false)}
                      >
                        {translations.Header.EBook}
                      </NavLink>
                    </li>
                    <li>
                      <NavLink to="/#price" onClick={() => scrollToSection('price')}>
                      {translations.Header.Price}
                      </NavLink>
                    </li>
                    <li>
                      <Link onClick={() => {setMobileToggle(false); handleLogin()}}>
                      {translations.Header.Dashboard}
                      </Link>
                    </li>
                  </ul>
                  <span
                    className={
                      mobileToggle
                        ? 'cs-munu_toggle cs-toggle_active'
                        : 'cs-munu_toggle'
                    }
                    onClick={() => setMobileToggle(!mobileToggle)}
                  >
                    <span></span>
                  </span>
                </Div>
              </Div>
              <Div className="cs-main_header_right">
                <Div className="cs-nav cs-primary_font cs-medium">
                  <ul
                    className="cs-nav_list"
                    style={{ display: `${mobileToggle ? 'block' : 'none'}` }}
                  >
                    <li className="menu-item-has-children">
                      <NavLink onClick={() => setMobileToggle(false)}>
                        {selectedLanguage}
                      </NavLink>
                      <DropDown>
                        <ul>
                          <li>
                            <Link
                              to="/"
                              onClick={() => handleLanguageChange('TKM')}
                            >
                              Turkmen
                            </Link>
                          </li>
                          <li>
                            <Link onClick={() => handleLanguageChange('EN')}>
                              English
                            </Link>
                          </li>
                          {/* <li>
                            <Link onClick={() => handleLanguageChange('RU')}
                            >
                              Russian
                            </Link>
                          </li> */}
                        </ul>
                      </DropDown>
                    </li>
                  </ul>
                </Div>
              </Div>
            </Div>
          </Div>
        </Div>
      </header>
    </>
  );
}