// languageManager.js
import en from './en.json';
import ru from './ru.json';
import tr from './tr.json';


const languages = {
  'EN': en,
  'RU': ru,
  'TKM': tr
};

export function getTranslation(language) {
  return languages[language];
}
