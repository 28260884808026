import * as React from 'react';
import { useState, useEffect  } from 'react';
import { styled, useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import CssBaseline from '@mui/material/CssBaseline';
import MuiAppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import ChapterList from '../components/ChapterList';
import MenuList from '../components/MenuList';
import { CardMedia } from '@mui/material';
import LogoutIcon from '@mui/icons-material/Logout';
import ListSubheader from '@mui/material/ListSubheader';
import List from '@mui/material/List';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import { viewCourse } from '../APIs/ViewCourse';

import Button from '@mui/material/Button';
import { ThemeProvider, createTheme } from '@mui/material/styles';


const darkTheme = createTheme({
  palette: {
    mode: 'dark',
  },
  typography: {
    fontFamily: 'Raleway, Arial, sans-serif',
  },
});

const drawerWidth = 240;

const Main = styled('main', { shouldForwardProp: (prop) => prop !== 'open' })(
  ({ theme, open }) => ({
    flexGrow: 1,
    padding: theme.spacing(3),
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    marginLeft: `-${drawerWidth}px`,
    ...(open && {
      transition: theme.transitions.create('margin', {
        easing: theme.transitions.easing.easeOut,
        duration: theme.transitions.duration.enteringScreen,
      }),
      marginLeft: 0,
    }),
  }),
);

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== 'open',
})(({ theme, open }) => ({
  transition: theme.transitions.create(['margin', 'width'], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    width: `calc(100% - ${drawerWidth}px)`,
    marginLeft: `${drawerWidth}px`,
    transition: theme.transitions.create(['margin', 'width'], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const DrawerHeader = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
  justifyContent: 'flex-end',
}));

export default function PersistentDrawerLeft() {
  const theme = useTheme();
  const [open, setOpen] = React.useState(true);
  // const navItems = ['CRYPTO', 'BLOCKCHAIN', 'NFT', 'ENGLISH'];
  const [isMobileView, setIsMobileView] = useState(false);
  const [chapters, setChapters] = useState();

  useEffect(() => {
    function handleResize() {
      setIsMobileView(window.innerWidth < 600);
    }

    // Run handleResize initially
    handleResize();

    // Add event listener for resize event
    window.addEventListener('resize', handleResize);

    // Cleanup function to remove event listener when component unmounts
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  const [selectedVideo, setSelectedVideo] = useState(null);

  const handleVideoClick = (video) => {
    setSelectedVideo(video);
  };


  useEffect(() => {
    async function fetchData() {
      try {
        const data = await viewCourse();
        if (data) {
        // Remove surrounding quotes from the chapters string
        const sanitizedChaptersString = data.chapters.replace(/^"|"$/g, '');
        // Parse the sanitized string into a JavaScript object
        const chaptersObject = JSON.parse(sanitizedChaptersString);
        setChapters(chaptersObject);
        console.log(chapters);
        } else {
          console.error('Empty response received from the API');
        }
      } catch (error) {
        console.error('Error fetching courses:', error);
      }
    }
  
    fetchData();
    // eslint-disable-next-line
  }, []);



  const handleLogout = () => {
    // Clear session or perform logout actions here
    sessionStorage.clear(); // Clear session storage
  };

  return (
    <Box sx={{ display: 'flex', backgroundColor : '#000000'}}>
      <ThemeProvider theme={darkTheme} >
      <CssBaseline />
      <AppBar position="fixed" open={open} sx={{ height: isMobileView ? '7.5vh' : '7vh', justifyContent: 'center', backgroundColor : '#000000' }}>
        <Toolbar sx={{ display: 'flex', justifyContent: 'space-between' }}>
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            onClick={handleDrawerOpen}
            edge="start"
            sx={{ mr: 2, ...(open && { display: 'none' }) }}
          >
            <MenuIcon />
          </IconButton>
          <img src='/dash-images/logo.svg' alt="GCrypto Logo" style={{ height: isMobileView ? '3.5vh' : '3.5vh', marginRight: '10px', paddingTop :'4px' }} /> {/* SVG Logo */}
          </Box>
          <Box sx={{ display: isMobileView ? 'none' : 'block' , alignItems: 'center'}}>
            <Button href="/ " sx={{ marginLeft: '20px', fontSize: '12px',
            color: 'white',
            '&:hover': {
              color: '#0085ff', // Hover background color
            }
            }}
            >
              Home
            </Button>
            <Button href="/Dashboard " sx={{ marginLeft: '0px', fontSize: '12px',
            color: 'white',
            '&:hover': {
              color: '#0085ff', // Hover background color
            }
            }}
            >
              Dashboard
            </Button>
          </Box>
          <Box sx={{ display: isMobileView ? 'none' : 'block' , alignItems: 'center' }}>
          <Button onClick={handleLogout}  href="/ "
            startIcon={<LogoutIcon />} 
            sx={{
            color: 'white',
            fontSize: '12px',
            '&:hover': {
              color: '#0085ff', // Hover background color
            }
            }}
            >
              Logout
          </Button>
          </Box>
        </Toolbar>
      </AppBar>
      <Drawer
        sx={{
          width: drawerWidth,
          flexShrink: 0,
          '& .MuiDrawer-paper': {
            width: drawerWidth,
            boxSizing: 'border-box',
          },
        }}
        variant="persistent"
        anchor="left"
        open={open}
      >
        <DrawerHeader>
          <IconButton onClick={handleDrawerClose}>
            {theme.direction === 'ltr' ? <ChevronLeftIcon /> : <ChevronRightIcon />}
          </IconButton>
        </DrawerHeader>
        <Divider />
        <MenuList isMobileView={!isMobileView} />
        <Divider />
        {chapters && <ChapterList chapters={chapters} onVideoClick={handleVideoClick} />}
        <Divider />
        <Box style={{ display: !isMobileView ? 'none' : 'block' }}>
        <List
          sx={{ width: '100%', maxWidth: 50, bgcolor: 'background.paper' }}
          component="nav"
          aria-labelledby="nested-list-subheader"
          subheader={
            <ListSubheader component="div" id="nested-list-subheader">
              Settings
            </ListSubheader>
          }
        >
        <ListItemButton>
          <ListItemIcon>
            <LogoutIcon />
          </ListItemIcon>
          <ListItemText primary="Logout" />
        </ListItemButton>
      </List>
        </Box>
      </Drawer>
      <Main open={open}>
        <DrawerHeader />
        {selectedVideo && (
        <CardMedia sx={{ position: 'absolute', top: 0, left: 0, width: '100%', height: '100%' }}
              component="iframe"
              src={selectedVideo.url}
              title={selectedVideo.title}
              allowFullScreen
        />
        )}
        
      </Main>
      </ThemeProvider>
    </Box>
  );
}
