import * as React from 'react';
import ListSubheader from '@mui/material/ListSubheader';
import List from '@mui/material/List';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Collapse from '@mui/material/Collapse';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import Divider from '@mui/material/Divider';
import ListRoundedIcon from '@mui/icons-material/ListRounded';
import SlideshowRoundedIcon from '@mui/icons-material/SlideshowRounded';

export default function NestedList({ chapters, onVideoClick }) {
  const [openChapters, setOpenChapters] = React.useState({});

  const handleClick = (chapterId) => {
    setOpenChapters((prevState) => ({
      ...prevState,
      [chapterId]: !prevState[chapterId],
    }));
  };

  return (
    <List
      sx={{ width: '100%', maxWidth: 360, bgcolor: 'background.paper' }}
      component="nav"
      aria-labelledby="nested-list-subheader"
      subheader={
        <ListSubheader component="div" id="nested-list-subheader">
          Course Chapters
        </ListSubheader>
      }
    >
      {chapters.map((chapter, index) => (
        <div key={index}>
          <ListItemButton onClick={() => handleClick(chapter.id)}>
            <ListItemIcon>
              <ListRoundedIcon />
            </ListItemIcon>
            <ListItemText primary={chapter.title} />
            {openChapters[chapter.id] ? <ExpandLess /> : <ExpandMore />}
          </ListItemButton>
          <Collapse in={openChapters[chapter.id]} timeout="auto" unmountOnExit>
            <List component="div" disablePadding>
              {chapter.videos.map((video, videoIndex) => (
                <ListItemButton sx={{ pl: 4 }} key={videoIndex} onClick={() => onVideoClick(video)}>
                  <ListItemIcon>
                    <SlideshowRoundedIcon />
                  </ListItemIcon>
                  <ListItemText primary={video.title} />
                </ListItemButton>
              ))}
            </List>
          </Collapse>
          <Divider />
        </div>
      ))}
    </List>
  );
}
