// Define the API endpoint
const API_URL = 'https://gcrypto.azurewebsites.net/api/GcCourses';
const accessToken = sessionStorage.getItem('accessToken');
const userId = sessionStorage.getItem('userId');
const urlParams = new URLSearchParams(window.location.search);
const courseId = urlParams.get('courseId'); 

// Function to fetch data from the API
async function viewCourse() {
  let response;
  try {
    response = await fetch(`${API_URL}/view?courseId=${courseId}&userId=${userId}`,{
        method: 'GET',
        headers: {
            'Authorization': `Bearer ${accessToken}`
        }
    });

    if (!response.ok) {
      throw new Error('Error fetching data');
    }

    const data = await response.json();
    return data;
  } catch (error) {
    console.error('Error fetching data:', error.message);

    if (response && response.status === 401) {
      window.location.href = '/login';
    } else if (response && response.status === 403) {
      const errorBody = await response.json();
      if (errorBody.redirectPath) {
        window.location.href = errorBody.redirectPath;
      } else {
        window.location.href = '/payment';
      }
    } else {
      window.location.href = '/error';
    }

    return null;
  }
}

export { viewCourse };
