import React, { useState} from 'react';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import Link from '@mui/material/Link';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { sendRefferal } from '../APIs/Refferal'; // Adjust the path as needed
import Modal from '../components/Modal/Modal';

function Copyright(props) {
  return (
    <Typography variant="body2" color="text.secondary" align="center" {...props}>
      {'Copyright © '}
      <Link color="inherit" href="https://mui.com/">
        Your Website
      </Link>{' '}
      {new Date().getFullYear()}
      {'.'}
    </Typography>
  );
}

// TODO remove, this demo shouldn't need to reset the theme.


const darkTheme = createTheme({
    palette: {
      mode: 'dark',
    },
    typography: {
      fontFamily: 'Raleway, Arial, sans-serif',
    },
  });

export default function Referlink() {

  const [email, setEmail] = useState('');
  const [error, setError] = useState('');
  const [message, setMessage] = useState('');
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [emailError, setEmailError] = useState('');
  
  const validateEmail = (email) => {
    // Regular expression for validating email addresses
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };

  const handleEmailChange = (e) => {
    setEmail(e.target.value);
    // Clear email error message when the user types
    setEmailError('');
  };

  const handleButtonClick = () => {
    setIsModalOpen(true);
  };


  const handleSubmit = async (event) => {
    event.preventDefault();
    if (!validateEmail(email)) {
      setEmailError('Please enter a valid email address');
      return;
    }
    try {
      const refferalData = await sendRefferal(email);
      setMessage(refferalData);
    } catch (error) {
      setError(error.message); // Set the error message
    }
  };

  return (
    <ThemeProvider theme={darkTheme}>
      <Container component="main" maxWidth="xs">
        <CssBaseline />
        <Box
          sx={{
            marginTop: 8,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            width: '100%', // Take up 100% width
            height: '100vh',
          }}
        >
          <a href='/'><img alt="" src='/dash-images/logo.svg' href="/" style={{ height: '40px', marginBottom: '25px'}} /> </a>
          
          <Typography component="text" variant="h5" align="center">
            Send invitation to your friend <br/>Earn 5$
          </Typography>
          <Box component="form"  onSubmit={handleSubmit} sx={{ mt: 3 }}>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <TextField
                  required
                  fullWidth
                  id="Email"
                  label="Email"
                  name="email"
                  value={email}
                  onChange={handleEmailChange}
                  error={!!emailError}
                  helperText={emailError}
                />
              </Grid>
              <Grid item xs={12}>
                <Typography component="text" variant="h5" sx ={{fontSize : '12px', justifyContent : 'Center'}}>
                Once the invitation is sent, your referees must make payment to the plan (Basic or VIP) before you can claim your rewards.
                </Typography>
              </Grid>
              {/* Error message */}
              {error && (
                <Grid item xs={12}>
                  <Typography variant="body2" color="error">{error}</Typography>
                </Grid>
              )}
              {message && (
                <Grid item xs={12}>
                  <Typography variant="body2" color="#ffffff">{message}</Typography>
                </Grid>
              )}
            </Grid>
            <Button
              type="submit"
              fullWidth
              variant="contained"
              sx={{ mt: 3, mb: 2,
                color: 'white', // Text color
                backgroundColor: '#0060ff', // Background color
                '&:hover': {
                    backgroundColor: '#0085ff', // Hover background color
                }
            }}
            onClick={handleButtonClick}
            >
              Submit
            </Button>
            {isModalOpen && <Modal title={''} subtitle={''} description={''}/>} {/* Render Modal if isModalOpen is true */}
            <Grid container justifyContent="flex-end">
              <Grid item>
                <Link href="/dashboard" variant="body2" sx={{ color: '#0085ff'}}>
                  Dashboard
                </Link>
              </Grid>
            </Grid>
          </Box>
        </Box>
        <Copyright sx={{ mt: 5 }} />
      </Container>
    </ThemeProvider>
  );
}