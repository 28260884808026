import * as React from 'react';
import ListSubheader from '@mui/material/ListSubheader';
import List from '@mui/material/List';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
// import Collapse from '@mui/material/Collapse';
import InboxIcon from '@mui/icons-material/MoveToInbox';
import HomeRoundedIcon from '@mui/icons-material/HomeRounded';
// import ExpandLess from '@mui/icons-material/ExpandLess';
// import ExpandMore from '@mui/icons-material/ExpandMore';
import Box from '@mui/material/Box';
import { Link } from 'react-router-dom';
import PersonAddAltRoundedIcon from '@mui/icons-material/PersonAddAltRounded';

export default function NestedList({ isMobileView, disable }) {
  // const [open, setOpen] = React.useState(true);

  // const handleClick = () => {
  //   setOpen(!open);
  // };


  return (
    <Box style={{ display: isMobileView ? 'none' : 'block' }}>
    <List
      sx={{ width: '100%', maxWidth: 360, bgcolor: 'background.paper' }}
      component="nav"
      aria-labelledby="nested-list-subheader"
      subheader={
        <ListSubheader component="div" id="nested-list-subheader">
          Menu
        </ListSubheader>
      }
    >
      <ListItemButton component={Link} to="/">
        <ListItemIcon>
          <HomeRoundedIcon />
        </ListItemIcon>
        <ListItemText primary="Home"  />
      </ListItemButton>
      <ListItemButton component={Link} to="/dashboard">
        <ListItemIcon>
          <InboxIcon />
        </ListItemIcon>
        <ListItemText primary="Dashboard"  />
      </ListItemButton>
      <ListItemButton component={Link} to="/referlink" disabled={disable}>
        <ListItemIcon>
          <PersonAddAltRoundedIcon />
        </ListItemIcon>
        <ListItemText primary="Referral"  />
      </ListItemButton>
    </List>
    </Box>
  );
}