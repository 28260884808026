import * as React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { styled, css } from '@mui/system';
import { Modal as BaseModal } from '@mui/base/Modal';
import Button from '../Button';

export default function ModalUnstyled({enable}) {
  // const [open, setOpen] = React.useState(false);
  // const handleClose = () => setOpen(false);
  const handleLogout = () => {
    console.log("Logout button clicked");
    sessionStorage.clear(); // Clear session storage
  };


  return (
    <div>
      <Modal
        aria-labelledby="unstyled-modal-title"
        aria-describedby="unstyled-modal-description"
        open={enable}
        slots={{ backdrop: StyledBackdrop }}
      >
        <ModalContent sx={{ height: '500px',width: 375}}>
            <>
            <h2 id="unstyled-modal-title" className="modal-title" style={{in : 'center', fontSize : "14px"}}>
            Garaşylýan Töleg Barlagy
            </h2>
            <h2 id="unstyled-modal-title" className="modal-title" style={{fontSize : "12px"}}>
            Hasabyňyz töleg tassyklamak işinde
            </h2>
            <h2 id="unstyled-modal-title" className="modal-title" style={{fontSize : "12px"}}>
            Eger tölegi bank geçirmesi arkaly amala aşyran bolsaňyz <br />  <br /> Töleg tassyklamasyny email’imize iberiň: <br /> <strong>greattuniversity@gmail.com</strong>
            </h2>
            <h2 id="unstyled-modal-description" className="modal-title" style={{fontSize : "12px"}}>
            Biz barlanymyzdan soñra kursa girmäne we okap başlamana mümkinçilik berýäri.
            </h2>
            <h2 id="unstyled-modal-description" className="modal-title" style={{fontSize : "12px"}}>
            Eger entek töleg geçirmedik bolsaňyz, tölegiñizi tamamlamagyňyzy haýyş edýäris.
            </h2>
            <div style={{display: 'flex', justifyContent: 'space-between'}}>
            <Button btnLink='/payment' btnText='Payment'sx={{ color: '#0036ff'}} />
            <a href="/" onClick={handleLogout} style={{ color: '#0036ff', textDecoration: 'none',fontWeight: 'bold' }}>Logout</a>
            </div>              

          </>
        </ModalContent>

      </Modal>
    </div>
  );
}

const Backdrop = React.forwardRef((props, ref) => {
  const { open, className, ...other } = props;
  return (
    <div
      className={clsx({ 'base-Backdrop-open': open }, className)}
      ref={ref}
      {...other}
    />
  );
});

Backdrop.propTypes = {
  className: PropTypes.string.isRequired,
  open: PropTypes.bool,
};



const grey = {
  50: '#F3F6F9',
  100: '#E5EAF2',
  200: '#DAE2ED',
  300: '#C7D0DD',
  400: '#B0B8C4',
  500: '#9DA8B7',
  600: '#6B7A90',
  700: '#434D5B',
  800: '#303740',
  900: '#1C2025',
};

const Modal = styled(BaseModal)`
  position: fixed;
  z-index: 1300;
  inset: 0;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const StyledBackdrop = styled(Backdrop)`
  z-index: -1;
  position: fixed;
  inset: 0;
  background-color: rgb(0 0 0 / 0.5);
  -webkit-tap-highlight-color: transparent;
`;

const ModalContent = styled('div')(
  ({ theme }) => css`
    font-family: 'IBM Plex Sans', sans-serif;
    font-weight: 500;
    text-align: start;
    position: relative;
    display: flex;
    flex-direction: column;
    gap: 8px;
    overflow: hidden;
    background-color: ${theme.palette.mode === 'dark' ? grey[900] : grey[900]};
    border-radius: 2px;
    border: 1px solid #0038ff;
    box-shadow: 0 4px 12px
      ${theme.palette.mode === 'dark' ? 'rgb(0 0 0 / 0.5)' : 'rgb(0 0 0 / 0.2)'};
    padding: 24px;
    color: ${theme.palette.mode === 'dark' ? grey[50] : grey[900]};

    & .modal-title {
      margin: 0;
      line-height: 1.5rem;
      margin-bottom: 8px;
    }

    & .modal-description {
      margin: 0;
      line-height: 1.5rem;
      font-weight: 400;
      color: ${theme.palette.mode === 'dark' ? grey[400] : grey[800]};
      margin-bottom: 4px;
    }
  `,
);

