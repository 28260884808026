// api.js
const API_URL = 'https://gcrypto.azurewebsites.net/api/Users';

async function loginUser(email, password) {

const data = {
    email: email,
    password: password  // You'll need to add password input and retrieve its value
};
  try {
    const response = await fetch(`${API_URL}/Login`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(data),
    });
    
    if (!response.ok) {
      const errorBody = await response.text(); // Get the response body
      throw new Error(errorBody);
    }
    
    return await response.json();
  } catch (error) {
    console.error('Error during login:', error);
    throw error;
  }
}

export { loginUser };
