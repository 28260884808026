import React from 'react';
import parse from 'html-react-parser';
import './hero.scss';
// import Button from '../Button';
import Div from '../Div';
import VerticalLinks from '../VerticalLinks';
// import { Link } from 'react-router-dom';

export default function Hero({
  stitle,
  title,
  subtitle,
  // btnText,
  // btnLink,
  scrollDownId,
  socialLinksHeading,
  heroSocialLinks,
  bgImageUrl,
}) {
  return (
    <Div
      className="cs-hero cs-style1 text-center cs-bg cs-fixed_bg cs-shape_wrap_1"
      style={{ backgroundImage: `url(${bgImageUrl})` }}
    >
      {/* <Div className="cs-shape_1"  style={{ backgroundImage: `url(./service_icon_1.svg)` }}/>
      <Div className="cs-shape_1" />
      <Div className="cs-shape_1" /> */}
      <Div className="container">
        <Div className="cs-hero_text">
          <h1 className="cs-hero_title" style={{fontSize : '22px'}}>{parse(stitle)}</h1>
          <h1 className="cs-hero_title" >{parse(title)}</h1>
          <Div className="cs-hero_info justify-content-center">
            <Div className="cs-hero_subtitle">{subtitle}</Div>
          </Div>
          <div style={{ margin: '50px' }}></div>
          <Div className="cs-hero_info justify-content-center">
            {/* <div>
            <Button className="cs-btn cs-style1" btnLink={btnLink} btnText={btnText} />
            </div> */}
            <Div className="col-sm-12">
                <button className="cs-btn cs-style1">  
                  <a
                    href="/register"
                    // target="_blank"
                    // rel="noopener noreferrer"
                  >
                    <span>START LEARNING NOW</span>
                  </a>
                  {/* <Icon icon="bi:arrow-right" /> */}
                </button>
            </Div>
            {/* <Div>
              <Button btnLink={btnLink} btnText={btnText} />
            </Div> */}
            {/* <Div>
              <Div className="cs-hero_subtitle">{subtitle}</Div>
            </Div> */}
          </Div>
        </Div>
      </Div>
      <VerticalLinks data={heroSocialLinks} title={socialLinksHeading} />
      <a href={scrollDownId} className="cs-down_btn">
        .
      </a>
    </Div>
  );
}
