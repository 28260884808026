import React, {useEffect, useState} from 'react';
import Link from '@mui/material/Link';
import Typography from '@mui/material/Typography';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import PricingTableList from '../components/PricingTableHome/PricingTableList';
import SectionHeading from '../components/SectionHeading';
import Div from '../components/Div';
import Spacing from '../components/Spacing';
import Cta from '../components/Cta'

function Copyright(props) {
  return (
    <Typography variant="body2" color="text.secondary" align="center" {...props}>
      {'Copyright © '}
      <Link color="inherit" href="/">
        GCrypto
      </Link>{' '}
      {new Date().getFullYear()}
      {'.'}
    </Typography>
  );
}

// TODO remove, this demo shouldn't need to reset the theme.

const darkTheme = createTheme({
    palette: {
      mode: 'dark',
    },
    typography: {
      fontFamily: 'Raleway, Arial, sans-serif',
    },
  });


// eslint-disable-next-line
export default function Payment() {

  const [userId, setUserId] = useState(null);

  useEffect(() => {
    // Fetch userId from session or wherever it's stored
    const userIdFromSession = sessionStorage.getItem('userId'); // Example: Retrieving from sessionStorage
    setUserId(userIdFromSession);
  }, []);

const pricingModel = { 
  monthly: [
    {id: 0, timeline: 'monthly', title: 'Bank Transfer', price: '', currency: '', features: ['VIP Plan', 'Basic Plan'], btnText: 'Purchase Now' ,btnLink: `/BankTransfer` },
    {id: 1, timeline: 'monthly', title: 'Online Payment', price: '', currency: '', features: ['VIP Plan', 'Basic Plan'], btnText: 'Purchase Now' ,btnLink: `/subscription` }
  ],
  yearly: [
    {id: 0, timeline: 'yearly', title: 'Basic', price: '444', currency: '$', features: ['Courses','Crypto','Mindset'], btnText: 'Purchase Now' ,btnLink: `https://gcrypto.azurewebsites.net/api/GcSubscriptions/create-checkout-session?userId=${userId}&subscriptionPlan=Basic&yearlyPlan=true` },
    {id: 1, timeline: 'yearly', title: 'VIP', price: '1164', currency: '$', features: ['All Courses', 'Books', 'Telegram Chat'], btnText: 'Purchase Now' , btnLink: `https://gcrypto.azurewebsites.net/api/GcSubscriptions/create-checkout-session?userId=${userId}&subscriptionPlan=VIP&yearlyPlan=true`}
  ]
};


  return (
    <ThemeProvider theme={darkTheme}>
      <Div className="container">
        <Div style={{ margin: 'auto', paddingTop: '10vh', display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
        <a href='/'><img alt="" src='/dash-images/logo.svg' href="/" style={{ height: '40px', marginBottom: '25px'}} /> </a>
          <Typography component="text" variant="h5"  >
            Please choose your payment methode
          </Typography>
        </Div>
        <Spacing lg="85" md="40" />
        <SectionHeading
          title="Payment Methode"
          subtitle=""
        />
        <Spacing lg="85" md="40" />
        <PricingTableList pricingModel={pricingModel} />
        <Spacing lg='50' md='20'/>
      </Div>
      <Cta 
        title="Let’s disscuse <br>greattuniversity@gmail.com"
        bgSrc='/images/cta_bg_2.jpeg'
        variant='rounded-0'
      />
      <Spacing lg="125" md="55" />
      <Copyright sx={{ mt: 0, paddingBottom : '50px' }} />
    </ThemeProvider>
  );
}
