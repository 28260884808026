// api.js
const API_URL = 'https://gcrypto.azurewebsites.net/api/Users';

async function verifyUser(userId, code) {

  try {
    const response = await fetch(`${API_URL}/Verify?userId=${userId}&code=${code}`, {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
      },
      
    });
    
    if (!response.ok) {
        const errorBody = await response.text(); // Get the response body
        throw new Error(errorBody);
    }
    
    return await response.json();
  } catch (error) {
    console.error('Error during register:', error);
    throw error;
  }
}

export { verifyUser };
