// import { Icon } from '@iconify/react';
import React, { useState,useEffect } from 'react';
import Div from '../Div';
// import Hero2 from '../Hero/Hero2';
import Hero from '../Hero';
import PricingTableList from '../PricingTableHome/PricingTableList';
import SectionHeading from '../SectionHeading';
import Spacing from '../Spacing';
import { pageTitle } from '../../helper';
import IconBox from '../IconBox';
import Portfolio2 from '../Portfolio/Portfolio2';
import Portfolio3 from '../Portfolio/Portfolio3';
import Button from '../Button';
import ServiceList from '../ServiceList';
import Accordion from '../Accordion';
import MovingText2 from '../MovingText/MovingText2';
import Cta from '../Cta'
import Team from '../Team';
import Header from '../Header';
import { getTranslation } from './languageManager';
import Footer from '../Footer';

export default function GCHome({ headerVariant }) {
  pageTitle('GCrypto');
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const [selectedLanguage, setSelectedLanguage] = useState('TKM');
  const translations = getTranslation(selectedLanguage);

  // Function to handle language change
  const handleLanguageChange = (language) => {
    setSelectedLanguage(language);
  };
  // Hero Social Links
  const heroSocialLinks = [
    {
      name: 'Instagram',
      links: 'https://www.instagram.com/greattuniversity?igsh=MzRlODBiNWFlZA==',
    },
    {
      name: 'X',
      links: 'https://twitter.com/?lang=en',
    },
  ];

  return (
    <>
      <Header selectedLanguage={selectedLanguage} onLanguageChange={handleLanguageChange} translations={translations} />
      {/* Start Hero Section */}
      <section id="home">
       <Hero
        stitle={translations.heroData.stitle}
        title={translations.heroData.title}
        subtitle={translations.heroData.subtitle}
        btnText={translations.heroData.btnText}
        btnLink="/register"
        scrollDownId="#service"
        socialLinksHeading={translations.heroData.socialLinksHeading}
        heroSocialLinks={heroSocialLinks}
        bgImageUrl={translations.heroData.bgImageUrl}
      />
      {/* End Hero Section */}
      </section>
      {/* End Hero Section */}

      {/* Start Services Section */}
      <Spacing lg="60" md="20" />
      <Div className="container">
        <SectionHeading
          title={translations.sectionServiceTitle1}
          subtitle={translations.sectionServiceSubtitle}
          variant="cs-style1 text-center"
        />
        <Spacing lg="65" md="45" />
      </Div>
      {/* End Services Section */}

      {/* Start Moving Text Section */}
      <MovingText2 data={translations.serviceData1} />
      <Spacing lg="20" md="10" />
      <MovingText2 reverseDirection data={translations.serviceData2} />
      {/* End Moving Text Section */}

      {/* Start Core-Services Section */}
      <section id="service">
      <Spacing lg="100" md="80" />
      <Div className="container">
        <SectionHeading
          title=""
          subtitle=""
          variant="cs-style1 text-center"
        />
        <Spacing lg="70" md="45" />
        <ServiceList serviceData={translations.serviceData} />
      </Div>
      </section>
      {/* End Core-Services Section */}

      {/* Start learflow Section */}
      <section id="strategie">
      <Spacing lg='145' md='80'/>
      <Div className="container">
        <SectionHeading 
          title={translations.learflowSectionTitle}
          subtitle={translations.learflowSectionSub} 
          variant='cs-style1 text-center'
        />
        <Spacing lg='90' md='45'/>
        <Div className="row">
          <Div className="col-lg-4">
            <IconBox
              icon='/images/icons/service_icon_1.svg'
              title={translations.learflowSubSectionTitle1}
              subtitle={translations.learflowSubSectionSub1} 
            />
            <Spacing lg='30' md='30'/>
          </Div>
          <Div className="col-lg-4">
            <IconBox
              icon='/images/icons/service_icon_2.svg'
              title={translations.learflowSubSectionTitle2}
              subtitle={translations.learflowSubSectionSub2} 
            />
            <Spacing lg='30' md='30'/>
          </Div>
          <Div className="col-lg-4">
            <IconBox
              icon='/images/icons/service_icon_3.svg'
              title={translations.learflowSubSectionTitle3}
              subtitle={translations.learflowSubSectionSub3} 
            />
            <Spacing lg='30' md='30'/>
          </Div>
        </Div>
      </Div>
      {/* <Spacing lg='120' md='50'/> */}
      {/* End learflow Section */}
      {/* Start Portfolio Section */}
      <Spacing lg="150" md="80" />
      {translations.portfolioData.map((item, index) =>
        index % 2 === 0 ? (
          <Div key={index}>
            <Portfolio2
              title={item.title}
              subtitle={item.subtitle}
              btnText={item.btnText}
              btnLink={item.btnLink}
              imageUrl={item.imageUrl}
              category={item.category}
            />
            <Spacing lg="100" md="70" />
          </Div>
        ) : (
          <Div key={index}>
            <Portfolio3
              title={item.title}
              subtitle={item.subtitle}
              btnText={item.btnText}
              btnLink={item.btnLink}
              imageUrl={item.imageUrl}
              category={item.category}
            />
            <Spacing lg="100" md="80" />
          </Div>
        ),
      )}
      {/* End Portfolio Section */}
      </section>

      {/* Start ForWhom Section */}
      {/* <Spacing lg='120' md='50'/> */}
      <Div className="container">
        <Div className="row align-items-center">
          <Div className="col-xl-5 col-lg-6">
            <Div className="cs-radius_15 cs-shine_hover_1">
              <img src="/images/6.jpg" alt="Service" className='cs-radius_15 w-100' />
            </Div>
            <Spacing lg='0' md='40'/>
          </Div>
          <Div className="col-lg-6 offset-xl-1">
            <h2 className="cs-font_50 cs-m0">{translations.forWhomSectionTitle}</h2>
            <Spacing lg='50' md='30'/>
            <Div className="row">
              <Div className="col-lg-6">
                <Button  btnLink='/' btnText={translations.forWhomSectionBtnText1} variant='cs-type2'/>
                <Spacing lg='20' md='10'/>
                <Button btnLink='/' btnText={translations.forWhomSectionBtnText2} variant='cs-type2'/>
                <Spacing lg='20' md='10'/>
                <Button btnLink='/' btnText={translations.forWhomSectionBtnText3} variant='cs-type2'/>
                <Spacing lg='20' md='10'/>
                <Button btnLink='/' btnText={translations.forWhomSectionBtnText4} variant='cs-type2'/>
                <Spacing lg='20' md='10'/>
                <Button btnLink='/' btnText={translations.forWhomSectionBtnText5} variant='cs-type2'/>
                <Spacing lg='20' md='10'/>
                <Button btnLink='/' btnText={translations.forWhomSectionBtnText6} variant='cs-type2'/>
                <Spacing lg='0' md='10'/>
              </Div>
              <Div className="col-lg-6">
                <Button btnLink='/' btnText={translations.forWhomSectionBtnText7} variant='cs-type2'/>
                <Spacing lg='20' md='10'/>
                <Button btnLink='/' btnText={translations.forWhomSectionBtnText8} variant='cs-type2'/>
                <Spacing lg='20' md='10'/>
              </Div>
            </Div>
          </Div>
        </Div>
      </Div>
      {/* <Spacing lg='50' md='80'/> */}
      {/* End ForWhom Section */}

      {/* Start FAQ's Section */}
      <section id="faq">
      <Spacing lg="100" md="40" />
      <Div className="container">
        <Div className="row">
          <Div className="col-lg-4">
            <Div className="cs-faq_nav cs-radius_15">
              <h2 className="cs-faq_nav_title cs-m0">FAQ</h2>
              <Div className="cs-height_30 cs-height_lg_30" />
            </Div>
          </Div>
          <Div className="col-lg-7 offset-lg-1">
            <Spacing lg="0" md="40" />
            <Accordion accordionData={translations.accordionData} />
          </Div>
        </Div>
      </Div>
      </section>
      {/* End FAQ's Section */}

      {/* Start Pricing Section */}
      <section id="price">
      <Spacing lg="150" md="80" />
      <Div className="container">
        <SectionHeading
          title={translations.SectionHeadingPriceTitle}
          subtitle={translations.SectionHeadingPriceSubTitle}
        />
        <Spacing lg="85" md="40" />
        <PricingTableList pricingModel={translations.pricingModel} />
      </Div>
      </section>
      {/* <Spacing lg="125" md="55" /> */}
      {/* End Pricing Section */}

      {/* Start Team Section */}
      <section id="team">
      <Spacing lg="145" md="80" />
      <Div className="container">
        <SectionHeading
          title={translations.teamSectionTitle}
          subtitle={translations.teamSectionSub}
          variant="cs-style1 text-center"
        />
        <Spacing lg="90" md="45" />
        <Div className="row" style={{display: 'flex', justifyContent: 'space-between'}}>
          {translations.teamData.map((item, index) => (
            <Div key={index} className="col-lg-3 col-sm-6">
              <Team
                memberImage={item.memberImage}
                memberName={item.memberName}
                memberDesignation={item.memberDesignation}
                memberSocial={item.memberSocial}
              />
              <Spacing lg="80" md="30" />
            </Div>
          ))}
        </Div>
      </Div>
      </section>
      {/* End Team Section */}
      
      <section id="contact">
      <Spacing lg='50' md='20'/>
      <Cta 
        title={translations.ctaTitle}
        bgSrc='/images/cta_bg_2.jpeg'
        variant='rounded-0'
      />
      <Spacing lg='50' md='20'/>
      </section>
      {/* Start Footer Section */}
      <Footer />
    </>
  );
}

