import React, { useEffect } from 'react';
import { pageTitle } from '../../helper';
import Cta from '../Cta';
import Div from '../Div';
import PageHeading from '../PageHeading';
import Spacing from '../Spacing';

export default function CaseStudyDetailsPage() {
  pageTitle('Privacy Policy');
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
      <PageHeading
        title="Policy on Personal Data Processing"
        bgSrc="/images/contact_hero_bg.jpeg"
        pageLinkText="Privacy Policy"
      />
      <Spacing lg="145" md="80" />
      <Div className="container">
        <h2 className="cs-font_38 text-center">1. General Provisions</h2>
        <Spacing lg="60" md="45" />
        <p className="cs-m0">
        This policy on the processing of personal data is compiled in accordance with the requirements of the Federal Law of 27.07.2006 No. 152-FZ "On Personal Data" (hereinafter referred to as the Personal Data Law) and defines the procedure for processing personal data and measures to ensure the security of personal data taken by The Greatt University (hereinafter referred to as the Operator).
        <br></br>1.1. The Operator sets compliance with the rights and freedoms of individuals and citizens in the processing of their personal data, including the protection of the right to privacy, personal, and family secrets, as its most important goal and condition for carrying out its activities.
        <br></br>1.2. This Operator's policy regarding the processing of personal data (hereinafter referred to as the Policy) applies to all information that the Operator may obtain about visitors to the website https://greattuniversity.com.
        </p>
        <Spacing lg="65" md="45" />
        <h2 className="cs-font_38 text-center">2. Key Concepts Used in the Policy</h2>
        <Spacing lg="60" md="45" />
        <p className="cs-m0 ">
        2.1. Automated processing of personal data - processing of personal data using computer technology.
        <br></br>2.2. Blocking of personal data - temporary suspension of the processing of personal data (except in cases where processing is necessary to clarify personal data).
        <br></br>2.3. Website - a set of graphic and informational materials, as well as programs for electronic computers and databases, providing their availability on the Internet at the network address https://greattuniversity.com.
        <br></br>2.4. Personal data information system - a set of personal data contained in databases and ensuring their processing by information technologies and technical means.
        <br></br>2.5. Depersonalization of personal data - actions as a result of which it is impossible to determine, without using additional information, the belonging of personal data to a specific User or other subject of personal data.
        <br></br>2.6. Processing of personal data - any action (operation) or set of actions (operations) performed with personal data using automation tools or without using such tools with personal data, including collection, recording, systematization, accumulation, storage, clarification (updating, modification), extraction, use, transfer (distribution, provision, access), depersonalization, blocking, deletion, destruction of personal data.
        <br></br>2.7. Operator - a state body, municipal body, legal entity, or individual who independently or jointly with other persons organizes and/or carries out the processing of personal data, as well as determines the purposes of processing personal data, the composition of personal data subject to processing, actions (operations) performed with personal data.
        <br></br>2.8. Personal data - any information directly or indirectly related to a specific or identifiable User of the website https://greattuniversity.com.
        <br></br>2.9. Personal data permitted for distribution by the subject of personal data - personal data to which unlimited access is granted by the subject of personal data by giving consent to the processing of personal data permitted for distribution by the subject of personal data in the manner provided by the Personal Data Law (hereinafter - personal data permitted for distribution).
        <br></br>2.10. User - any visitor to the website https://greattuniversity.com.
        <br></br>2.11. Provision of personal data - actions aimed at disclosing personal data to a specific person or a specific group of persons.
        <br></br>2.12. Distribution of personal data - any actions aimed at disclosing personal data to an indefinite circle of persons (transfer of personal data) or familiarizing an unlimited circle of persons with personal data, including the disclosure of personal data in the media, placement on information and telecommunication networks, or providing access to personal data in any other way.
        <br></br>2.13. Cross-border transfer of personal data - the transfer of personal data to the territory of a foreign state to the authority of a foreign state, a foreign individual, or a foreign legal entity.
        <br></br>2.14. Destruction of personal data - any actions as a result of which personal data are destroyed irreversibly with the impossibility of further restoring the content of personal data in the personal data information system and/or material carriers of personal data are destroyed.
        </p>
        <Spacing lg="65" md="45" />
        <h2 className="cs-font_38 text-center">3. Basic Rights and Obligations of the Operator</h2>
        <Spacing lg="60" md="45" />
        <p className="cs-m0 ">
        3.1. The Operator has the right to:
        <br></br>- receive from the subject of personal data accurate information and/or documents containing personal data;
        <br></br>- in case of withdrawal by the subject of personal data of consent to the processing of personal data, as well as the submission of a request to stop processing personal data, the Operator has the right to continue processing personal data without the consent of the subject of personal data if there are grounds specified in the Personal Data Law;
        <br></br>- independently determine the composition and list of measures necessary and sufficient to ensure the fulfillment of obligations provided for by the Personal Data Law and adopted in accordance with it regulatory legal acts, unless otherwise provided by the Personal Data Law or other federal laws.
        <br></br>3.2. The Operator is obliged to:
        <br></br>- provide the subject of personal data, upon his request, with information regarding the processing of his personal data;
        <br></br>- organize the processing of personal data in the manner established by the current legislation of the Russian Federation;
        <br></br>- respond to requests and inquiries from subjects of personal data and their legal representatives in accordance with the requirements of the Personal Data Law;
        <br></br>- provide the necessary information to the authorized body for the protection of the rights of subjects of personal data upon request of this body within 10 days from the date of receiving such request;
        <br></br>- publish or otherwise ensure unrestricted access to this Policy on the processing of personal data;
        <br></br>- take legal, organizational, and technical measures to protect personal data from unauthorized or accidental access to them, destruction, modification, blocking, copying, provision, dissemination of personal data, as well as from other unlawful actions regarding personal data;
        <br></br>- cease the transfer (distribution, provision, access) of personal data, stop processing, and destroy personal data in the manner and cases provided for by the Personal Data Law;
        <br></br>- fulfill other obligations provided for by the Personal Data Law.

        </p>
        <Spacing lg="65" md="45" />
        <h2 className="cs-font_38 text-center">4. Basic Rights and Obligations of Personal Data Subjects</h2>
        <Spacing lg="60" md="45" />
        <p className="cs-m0 ">
        4.1. Personal data subjects have the right to:
        <br></br>- receive information regarding the processing of their personal data, except as provided by federal laws. The information is provided to the subject of personal data by the Operator in an accessible form and should not contain personal data related to other subjects of personal data, except in cases where there are legitimate grounds for disclosing such personal data. The list of information and the procedure for obtaining it are established by the Personal Data Law;
        <br></br>- demand from the operator clarification of their personal data, their blocking or destruction in case the personal data are incomplete, outdated, inaccurate, unlawfully obtained, or unnecessary for the stated purpose of processing, as well as to take legal measures to protect their rights;
        <br></br>- impose a condition of prior consent when processing personal data for the purpose of promoting goods, works, and services on the market;
        <br></br>- withdraw consent to the processing of personal data, as well as submit a request to stop processing personal data;
        <br></br>- appeal to the authorized body for the protection of the rights of subjects of personal data or in court against the unlawful actions or inaction of the Operator in processing their personal data;
        <br></br>- exercise other rights provided for by the legislation of the Russian Federation.
        <br></br>4.2. Personal data subjects are obliged to:
        <br></br>- provide the Operator with accurate information about themselves;
        <br></br>- inform the Operator of the clarification (updating, changing) of their personal data.
        <br></br>4.3. Individuals who provide the Operator with inaccurate information about themselves, or information about another subject of personal data without the consent of the latter, bear responsibility in accordance with the legislation of the Russian Federation.
        </p>
        <Spacing lg="65" md="45" />
        <h2 className="cs-font_38 text-center">5. Principles of Personal Data Processing</h2>
        <Spacing lg="60" md="45" />
        <p className="cs-m0 ">
        5.1. Personal data processing is carried out on a legal and fair basis.
        <br></br>5.2. Processing of personal data is limited to achieving specific, predetermined, and lawful purposes. Processing of personal data that is incompatible with the purposes of collecting personal data is not allowed.
        <br></br>5.3. The combination of databases containing personal data processed for incompatible purposes is not allowed.
        <br></br>5.4. Only personal data that corresponds to the purposes of their processing are subject to processing.
        <br></br>5.5. The content and volume of processed personal data correspond to the stated purposes of processing. Redundancy of processed personal data in relation to the stated purposes of their processing is not allowed.
        <br></br>5.6. When processing personal data, accuracy, sufficiency, and, where necessary, relevance of personal data to the purposes of processing are ensured. The Operator takes necessary measures and/or ensures their adoption to delete or clarify incomplete or inaccurate data.
        <br></br>5.7. Storage of personal data is carried out in a form that allows identifying the subject of personal data no longer than required by the purposes of processing personal data, unless the period of personal data storage is established by federal law, a contract, a party to which, a beneficiary, or a guarantor of which is the subject of personal data. Processed personal data are destroyed or anonymized upon achieving the purposes of processing or in case of loss of the need to achieve these purposes, unless otherwise provided by federal law.
        </p>
        <Spacing lg="65" md="45" />
        <h2 className="cs-font_38 text-center">6. Purposes of Personal Data Processing</h2>
        <Spacing lg="60" md="45" />
        <p className="cs-m0 ">
        Purpose of Processing: Providing access to the User to services, information, and/or materials contained on the website
        <br></br>Personal Data: Last name, first name, patronymic (if available), email address, phone numbers
        <br></br>Legal Basis: Federal Law "On Information, Information Technologies, and Information Protection" dated July 27, 2006, No. 149-FZ
        <br></br>Types of Personal Data Processing: Sending informational emails to the email address
        </p>
        <Spacing lg="65" md="45" />
        <h2 className="cs-font_38 text-center">7. Conditions of Personal Data Processing</h2>
        <Spacing lg="60" md="45" />
        <p className="cs-m0 ">
        7.1. Personal data processing is carried out with the consent of the personal data subject to the processing of their personal data.
        <br></br>7.2. Processing of personal data is necessary to achieve the purposes provided for by an international treaty of the Russian Federation or by law, to perform functions, powers, and duties imposed by the legislation of the Russian Federation on the operator.
        <br></br>7.3. Processing of personal data is necessary to administer justice, enforce a court decision, an act of another authority, or an official, which must be enforced in accordance with the legislation of the Russian Federation on enforcement proceedings.
        <br></br>7.4. Processing of personal data is necessary for the performance of a contract, of which the personal data subject is a party, a beneficiary, or a guarantor, as well as for concluding a contract at the initiative of the personal data subject or a contract under which the personal data subject will be a beneficiary or a guarantor.
        <br></br>7.5. Processing of personal data is necessary to exercise the rights and legitimate interests of the operator or third parties or to achieve socially significant goals, provided that the rights and freedoms of the personal data subject are not violated thereby.
        <br></br>7.6. Processing of personal data is carried out for personal data that is made publicly available by the personal data subject or at their request (hereinafter referred to as publicly available personal data).
        <br></br>7.7. Processing of personal data is carried out for personal data subject to publication or mandatory disclosure in accordance with federal law.
        </p>
        <Spacing lg="65" md="45" />
        <h2 className="cs-font_38 text-center">8. Procedure for Collection, Storage, Transfer, and Other Types of Processing of Personal Data</h2>
        <Spacing lg="60" md="45" />
        <p className="cs-m0 ">
        The security of personal data processed by the Operator is ensured by implementing legal, organizational, and technical measures necessary to fully comply with the requirements of the current legislation in the field of personal data protection.

        <br></br>8.1. The Operator ensures the safety of personal data and takes all possible measures to prevent unauthorized access to personal data.

        <br></br>8.2. User's personal data will never, under any circumstances, be transferred to third parties, except in cases related to the execution of current legislation or if the data subject has given consent to the Operator to transfer data to a third party for the performance of obligations under a civil contract.

        <br></br>8.3. In case of inaccuracies in personal data, the User may update them independently by sending a notification to the Operator at the email address mgreatt12@gmail.com with the subject "Personal Data Update."

        <br></br>8.4. The processing period of personal data is determined by the achievement of the purposes for which personal data were collected, unless another period is provided by the contract or applicable law.

        <br></br>The User can withdraw their consent to the processing of personal data at any time by sending a notification to the Operator via email to the Operator's email address mgreatt12@gmail.com with the subject "Withdrawal of Consent to Personal Data Processing."

        <br></br>8.5. All information collected by third-party services, including payment systems, communication tools, and other service providers, is stored and processed by these entities (Operators) in accordance with their User Agreement and Privacy Policy. The data subject and/or with these documents. The Operator is not responsible for the actions of third parties, including service providers mentioned in this paragraph.

        <br></br>8.6. Prohibitions imposed by the data subject on the transfer (except for providing access), as well as on the processing or conditions of processing (except for obtaining access) of personal data permitted for distribution, do not apply in cases of processing personal data in the state, public, and other public interests defined by Russian legislation.

        <br></br>8.7. The Operator ensures the confidentiality of personal data during their processing.

        <br></br>8.8. The Operator stores personal data in a form that allows identifying the data subject for no longer than necessary for the purposes of processing personal data, unless the storage period of personal data is established by federal law, a contract, a party to which, a beneficiary, or a guarantor of which is the data subject.

        <br></br>8.9. The termination of personal data processing may be conditioned by achieving the purposes of processing personal data, expiration of the consent period of the personal data subject, withdrawal of consent by the personal data subject, or a request to cease processing personal data, as well as detecting unlawful processing of personal data.
        </p>
        <Spacing lg="65" md="45" />
        <h2 className="cs-font_38 text-center">9. List of actions performed by the Operator with the obtained personal data</h2>
        <Spacing lg="60" md="45" />
        <p className="cs-m0 ">
        <br></br> 9.1. The Operator collects, records, systematizes, accumulates, stores, clarifies (updates, changes), extracts, uses, transfers (distributes, provides, grants access), depersonalizes, blocks, deletes, and destroys personal data.

        <br></br>9.2. The Operator carries out automated processing of personal data with obtaining and/or transferring received information through information and telecommunication networks or without them.

        </p>
        <Spacing lg="65" md="45" />
        <h2 className="cs-font_38 text-center">10. Cross-Border Transfer of Personal Data</h2>
        <Spacing lg="60" md="45" />
        <p className="cs-m0 ">
        <br></br>10.1. Before commencing activities related to the cross-border transfer of personal data, the Operator must notify the authorized body for the protection of the rights of personal data subjects of its intention to carry out the cross-border transfer of personal data (such notification is sent separately from the notification of the intention to carry out personal data processing).

        <br></br>10.2. Before submitting the above notification, the Operator is obliged to obtain from the authorities of the foreign state, foreign individuals, foreign legal entities to whom the cross-border transfer of personal data is planned, the relevant information.

        </p>
        <Spacing lg="65" md="45" />
        <h2 className="cs-font_38 text-center">11. Confidentiality of Personal Data</h2>
        <Spacing lg="60" md="45" />
        <p className="cs-m0 ">
        The Operator and other persons who have access to personal data are obliged not to disclose personal data to third parties and not to distribute personal data without the consent of the data subject, unless otherwise provided by federal law.
        </p>
        <Spacing lg="65" md="45" />
        <h2 className="cs-font_38 text-center">12. Final Provisions</h2>
        <Spacing lg="60" md="45" />
        <p className="cs-m0 ">
        12.1. The User can obtain any clarifications on issues related to the processing of their personal data by contacting the Operator via email at <strong>mgreatt12@gmail.com.</strong>.

        <br></br>12.2. Any changes to the policy for processing personal data by the Operator will be reflected in this document. The policy is effective indefinitely until replaced by a new version.

        <br></br>12.3. The current version of the Policy is freely available on the Internet at <strong>https://crypto/greattuniversity.com/privacypolicy</strong>.

        </p>
        <Spacing lg="65" md="45" />
      </Div>
      <Spacing lg="145" md="80" />
      {/* Start CTA Section */}
      <Div className="container">
        <Cta 
          title="Let’s disscuse <br>contact@greattuniversity.com"
          bgSrc='/images/cta_bg_2.jpeg'
          variant='rounded-0'
        />
        <Spacing lg="125" md="55" />
      </Div>
      {/* End CTA Section */}
    </>
  );
}
