// Define the API endpoint
const API_URL = 'https://gcrypto.azurewebsites.net/api/GcCourses';
const accessToken = sessionStorage.getItem('accessToken');
const userId = sessionStorage.getItem('userId');


// Function to fetch data from the API
async function getCourse(category) {
  try {

    if (!accessToken || !userId) {
      window.location.href = '/login';
      return null;
    }

    const response = await fetch(`${API_URL}?category=${category}&userId=${userId}`,{
        method: 'GET',
        headers: {
            'Authorization': `Bearer ${accessToken}`
        }
    });
    
    if (!response.ok) {
      throw new Error('Failed to fetch data');
    }
    
    const data = await response.json();
    return data;
  } catch (error) {
    window.location.href = '/login';
    return null;
  }
}

export {getCourse};