import React, { useState, useEffect } from 'react';
import { Container, Row, Col, Card, Button, Form } from 'react-bootstrap';
import Header from '../components/DashHeader';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Typography from '@mui/material/Typography';
import { Box } from '@mui/material';
import { getCourse } from '../APIs/Course';
import Modal from '../components/Modal/Modal';

const categoryTitle = [
    { id: 1, title: 'Crypto' },
    { id: 2, title: 'Mindset' },
    { id: 3, title: 'English' },
];

export default function  Dashboard  ()  {
    const [selectedCategory, setSelectedCategory] = useState(99);
    const [courses, setCourses] = useState([]);
    const [data, setData] = useState();
    const [heading, setHeading] = useState('All Courses');
    const [disable, setDisable] = useState(false);
    const [modal, setModal] = useState(false);

    useEffect(() => {
        async function fetchData() {
            try {
                const data = await getCourse(selectedCategory);
                setCourses(data.courses);
                setData(data);
                if (data.paymentStatus !== 'paid' ){
                    setModal(true)
                    setDisable(true);
                }
                else{
                    setModal(false)
                    setDisable(false);
                }
            } catch (error) {
                // window.location.href = '/login';
                console.error('Error fetching courses:', error);
                // Handle error as needed
            }
        }

        fetchData();
    }, [selectedCategory]);

    const handleCategoryChange = (event) => {
        const category = parseInt(event.target.value, 10);
        setSelectedCategory(category);
        if (category === 99) {
            setHeading('All Courses');
        } else {
            const categoryTitleObject = categoryTitle.find((c) => c.id === category);
            setHeading(categoryTitleObject ? categoryTitleObject.title + ' Courses' : 'Unknown Category');
        }
    };


    return (
        <div>
        <Header disable={disable} />
        
        <div >
            <Box sx = {{backgroundColor: '#343a40', minHeight: '100vh', paddingTop: '15vh'}}>
            <Modal enable={modal}/>
            <Container className="text-light">
                <Row className="align-items-center">
                    <Col xs={6}>
                        <h2 className="mt-4 mb-4" style={{ fontSize: '26px'}} >{heading}</h2>
                    </Col>
                    <Col xs={6} >
                        <Form.Select className="mt-4 mb-4 bg-dark text-light" value={selectedCategory} onChange={handleCategoryChange} >
                        <option value='99'  >All</option>
                            <option value='1'>Crypto</option>
                            <option value='2'>Mindset</option>
                            <option value='3'>English</option>
                        </Form.Select>
                    </Col>
                </Row>
                <Row xs={1} md={2} lg={3} className="g-4">
                    {courses.map(course => (
                        
                        <Col key={course.id}>
                            <Card bg="dark" text="light" sx={{ maxWidth: 345 }}>
                                <CardMedia
                                    component="img"
                                    alt=""
                                    height="140"
                                    image={`https://gcrypto.azurewebsites.net/api/GcImage/${course.imagePath}`}
                                    style={{ width: '100%', height: '250px' }} // Ensure image fills the container
                               
                                />
                                <CardContent>
                                    <Typography gutterBottom variant="h5" component="div">
                                    {course.title}
                                    </Typography>
                                    <Typography variant="body2" >
                                    {course.description}
                                    </Typography>
                                </CardContent>
                                <CardActions>
                                    <Button size="small" href={`/view?courseId=${course.id}`} 
                                    disabled={data.paymentStatus !== 'paid' || (data.userOffer !== 'VIP' && course.category === 3)}
                                    >View Course</Button>
                                </CardActions>
                            </Card>
                        </Col>
                    ))}
                </Row>
            </Container>
            </Box>
        </div>
        </div>
    );
};

