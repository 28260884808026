// api.js
const API_URL = 'https://gcrypto.azurewebsites.net/api/Users';

async function registerUser(firstname, lastname, email, password) {

const data = {

    Firstname : firstname,
    Lastname : lastname,
    Email : email,
    Password : password,
    EmailAuth : 0,
    RegistrationDate : "NA",
    Verified : "NA"
};
  try {
    const response = await fetch(`${API_URL}/Register`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(data),
    });
    
    if (!response.ok) {
        const errorBody = await response.text(); // Get the response body
        throw new Error(errorBody);
    }
    
    return await response.json();
  } catch (error) {
    console.error('Error during register:', error);
    throw error;
  }
}

export { registerUser };
