import React from 'react'
import { useState } from 'react'
import PricingTable from '.'
import Section from '../Div'
import Spacing from '../Spacing'


export default function PricingTableList({ pricingModel }) {
  const [tab, setTab] = useState('ontime');
  return (
    <Section className="position-relative">
    <ul className="cs-tab_links cs-style1 cs-mp0 cs-primary_font" style={{display : 'none'}}>
      <li className={tab === 'ontime' ? "active" : ""} onClick={() => setTab('monthly')}>ontime</li>
    </ul>
    <Section className="row">
        {tab === 'ontime' && (
          <>
            {pricingModel.ontime.map(item => (
              <Section className="col-lg-4">
              <PricingTable
                key={item.id}
                title={item.title}
                price={item.price}
                currency={item.currency}
                timeline={item.timeline}
                features={item.features}
              />
              </Section>
            ))}
            <Spacing lg='25' md='25' />
          </>
        )}
    </Section>
  </Section>
  )
}
